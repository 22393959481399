import axios from 'axios'
import config from '../config/app.config';
import Vue from 'vue'
import router from '../router'

const client = axios.create({
    baseURL: config.api_url,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
});

client.interceptors.request.use(
    (response) => {
        let auth = Vue.$storage.get('auth');
        if (auth && auth.access_token) {
            response.headers['Authorization'] = "Bearer " + auth.access_token;
        }

        return response;
    },
    (error) => {

        return Promise.reject(error);
    }
)

//response interceptors
client.interceptors.response.use((response) => {
    if (response.data.data.access_token) {
        Vue.$storage.set('auth', response.data.data);
    }

    delete response.data.code;

    return response.data;

}, (error) => {

    var response = error.response.data;
    delete response.code;

    if (error.response.status === 401 && router.currentRoute.name !== "login") {
        Vue.$storage.remove('auth');
        router.push({name: 'login'});
        return;
    }
    return Promise.reject(response);

});


export default {
    client
}
