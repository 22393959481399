const config = {prefix: "/sctl", name: "sctl"};
export default [

    {
        path: config.prefix,
        name: `${config.name}.index`,
        component: () => import('./show.component.vue'),
        meta: {
            title: "NAA Parking"
        }
    }
];
