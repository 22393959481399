import moment from "moment";
import { roles } from "./utils/mockup";

export default {
  methods: {
    $label(id, type) {
      const label = {
        form_submit: id ? "UPDATE" : "CREATE",
      };
      return label[type];
    },
    $toError(errors, key) {
      var response = {
        status: false,
        message: "",
      };
      if (errors && errors[key]) {
        response = {
          status: true,
          message: errors[key][0],
        };
      }
      return response;
    },
    $toPageCount(total, per_page) {
      var total = total / per_page;

      return Math.ceil(total);
    },

    $datetime() {
      // Current date and time

      // 12hrs with am and pm
      const date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const strTime = hours + ":" + minutes + "" + ampm;

      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${strTime}`;

      // 24hrs
      // const d = new Date();
      // return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    },

    numberOnly(evt) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+"];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
      return (evt.target.value = evt.target.value.replace(/[^0-9+]/g, ""));
    },

    displayBookingStatus(x) {
      if (x == 0) return this.$t("booking-status.reserved");
      else if (x == 1) return this.$t("booking-status.entered");
      else if (x == 2) return this.$t("booking-status.exited");
      else if (x == 3) return this.$t("booking-status.cancelled");
      else return "--";
    },

    formatYMD(x) {
      return moment(x).format("YYYY-MM-DD");
    },

    hasCreateEditInventory() {
      if (this.$store.getters["roles"].some((role) => role == roles.super_admin || role == roles.create_edit_inventory)) return true;
      else return false;
    },

    hasCreateEditOrChangeInventory() {
      if (this.$store.getters["roles"].some((role) => role == roles.super_admin || role == roles.create_edit_inventory || role == roles.change_inventory)) return true;
      else return false;
    },

    hasUpdateReservationCalcSetting() {
      if (this.$store.getters["roles"].some((role) => role == roles.super_admin || role == roles.update_calc_setting)) return true;
      else return false;
    },

    hasAccount() {
      if (this.$store.getters["roles"].some((role) => role == roles.super_admin || role == roles.control_account)) return true;
    },

    hasEditFee() {
      if (this.$store.getters["roles"].some((role) => role == roles.super_admin || role == roles.edit_fee)) return true;
    },

    hasEditBooking() {
      if (this.$store.getters["roles"].some((role) => role == roles.super_admin || role == roles.edit_booking)) return true;
    },

    getYears() {
      return Array.from(Array(new Date().getFullYear() - 1978), (_, i) => (i + 1979).toString());
    },

    getMonths() {
      return Array.from({ length: 12 }, (v, k) => ({
        value: k + 1,
        text: `${k + 1}月`,
      }));
    },

    getDays() {
      return Array.from({ length: 31 }, (v, k) => ({
        value: k + 1,
        text: `${k + 1}日`,
      }));
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
};
