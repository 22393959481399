const config = {prefix: "/reservation/calendar-p5", name: "reservation/calendar-p5"};
export default [

    {
        path: config.prefix,
        name: `${config.name}.index`,
        component: () => import('./show.component.vue'),
        meta: {
            title: "NAA Parking"
        }
    }
];
