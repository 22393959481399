const config = {prefix: "/facility/booking/", name: "facility-booking"};
export default [

    {
        path: `${config.prefix}:id`,
        name: `${config.name}.show`,
        component: () => import('./show.component.vue'),
        meta: {
            title: "Booking"
        }
    }
];
