import {
    hasCreateEditInventory,
    hasCreateEditOrChangeInventory
} from '@/globals/roles';
const config = {prefix: "/facility/inventory", name: "facility-inventory"};
export default [

    {
        path: `${config.prefix}`,
        name: `${config.name}.index`,
        component: () => import('./list.component.vue'),
        meta: {
            title: "Inventory"
        }
    },
    {
        path: config.prefix + '/create',
        name: `${config.name}.create`,
        beforeEnter: hasCreateEditInventory,
        component: () => import('./form.component.vue'),
        meta: {
            title: "Create Inventory"
        }
    },
    {
        path: config.prefix + '/:id/edit',
        name: `${config.name}.edit`,
        beforeEnter: hasCreateEditOrChangeInventory,
        component: () => import('./form.component.vue'),
        meta: {
            title: "Edit Inventory"
        }
    },
];
