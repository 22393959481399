import Vue from 'vue';
import { Vue2Storage } from 'vue2-storage';
import App from './App.vue';
import router from './router';
import AppMixin from './app.mixin';
import i18n from './i18n';
import Paginate from 'vuejs-paginate';
import vuetify from './plugins/vuetify.js';

import 'bootstrap';
import './resources/assets/js/main';
// import store from './store'
import { BootstrapVue } from 'bootstrap-vue';

Vue.config.productionTip = false;
Vue.mixin(AppMixin);
Vue.use(Vue2Storage);
Vue.component('paginate', Paginate);

Vue.use(BootstrapVue);
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: function (h) { return h(App) },
  created: function() {
    store.dispatch('initialize');
    store.dispatch('roles');
  }
}).$mount('#app');
