const config = {prefix: "/auth/guest-login", name: "guest-login"};
export default [
    {
        path: config.prefix,
        name: `${config.name}`,
        component: () => import('./form.component.vue'),
        meta: {
            title: "Guest Login"
        }
    }
];
