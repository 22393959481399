import api from '../../globals/client';
import config from '../../config/app.config';

var promise;

export default class RolesService {
    show(id) {
        promise = api.client.get(config.end_point.users + '/' + id);
        return promise;
    }
}

export const rolesService = new RolesService();
