const config = {prefix: "/auth/email-authentication", name: "email-authentication"};
export default [
    {
        path: config.prefix,
        name: `${config.name}`,
        component: () => import('./form.component.vue'),
        meta: {
            title: "Create Account"
        }
    }
];
