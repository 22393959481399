<template>
  <div id="app">
    <router-view/>
  </div>

</template>

<style >
@import'./resources/assets/css/styles.css';
</style>
<script>

  export default {
    components: { },
    mounted() {

    }
  }
</script>
