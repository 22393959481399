export const reservation_status = {
  reserved: 0,
  entered: 1,
  exited: 2,
  cancelled: 3
}
export const roles = {
  super_admin: 1,
  control_account: 2,
  create_edit_inventory: 3,
  change_inventory: 4,
  edit_booking: 5,
  edit_fee: 8,
  update_calc_setting: 9,
}
// To enhance
export const years = [
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030',
  '2031',
  '2032'
]
export const months = [
  {
    name: "January",
    code: "jan",
    number: 1,
  },
  {
    name: "February",
    code: "feb",
    number: 2,
  },
  {
    name: "March",
    code: "mar",
    number: 3,
  },
  {
    name: "April",
    code: "apr",
    number: 4,
  },
  {
    name: "May",
    code: "may",
    number: 5,
  },
  {
    name: "June",
    code: "jun",
    number: 6,
  },
  {
    name: "July",
    code: "jul",
    number: 7,
  },
  {
    name: "August",
    code: "aug",
    number: 8,
  },
  {
    name: "September",
    code: "sept",
    number: 9,
  },
  {
    name: "October",
    code: "oct",
    number: 10,
  },
  {
    name: "November",
    code: "nov",
    number: 11,
  },
  {
    name: "December",
    code: "dec",
    number: 12,
  },
];