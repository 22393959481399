const config = {prefix: "/guest/", name: "guest-top"};
export default [

    {
        path: `${config.prefix}top`,
        name: `${config.name}.index`,
        component: () => import('./top.component.vue'),
        meta: {
            title: "Top"
        }
    }
];
