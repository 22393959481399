import TopRouter from '../resources/views/guest/top/app.router';
import DashboardRouter from '../resources/views/guest/dashboard/app.router';
import ReservationsRouter from '../resources/views/guest/reservations/app.router';
import ChargeRouter from '../resources/views/guest/charge/app.router';
import TermsRouter from '../resources/views/guest/terms/app.router';
import SettingRouter from '../resources/views/guest/setting/app.router';
const config = {prefix: "/guest"};
export default [
    
    {
        path: `${config.prefix}/top`,
        component: () => import('../resources/views/guest/top/app.component'),
        children: TopRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/dashboard`,
        component: () => import('../resources/views/guest/dashboard/app.component'),
        children: DashboardRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/reservations`,
        component: () => import('../resources/views/guest/reservations/app.component'),
        children: ReservationsRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/charge`,
        component: () => import('../resources/views/guest/charge/app.component'),
        children: ChargeRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/terms`,
        component: () => import('../resources/views/guest/terms/app.component'),
        children: TermsRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/setting`,
        component: () => import('../resources/views/guest/setting/app.component'),
        children: SettingRouter,
        meta: { }
    }
];
