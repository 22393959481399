const config = {prefix: "/qr/checkin", name: "qr-checkin"};
export default [

    {
        path: config.prefix,
        name: `${config.name}.index`,
        component: () => import('./list.component.vue'),
        meta: {
            title: "qr-checkin"
        }
    }
];
