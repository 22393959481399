
const config = {prefix: "/qr"};
import CheckinRouter from '../resources/views/qr/checkin/app.router';
export default [
    
    {
        path: `${config.prefix}/checkin`,
        component: () => import('../resources/views/qr/checkin/app.component'),
        children: CheckinRouter,
        meta: { }
    }
];
