import Vue from "vue";
import VueRouter from "vue-router";
import AuthRouter from "./auth.router";
import FacilityRouter from "./facility.router";
import GuestRouter from "./guest.router";
import QRRouter from "./qr.router";
import LandingRouter from "./landing.router";
import { guest, guestAuthorized, facilityAuthorized } from "@/globals/guard";

Vue.use(VueRouter);

const routes = [
  // landingページを利用しない決定につきコメントアウト（guestページで受け入れ）
  // {
  //   path: "/",
  //   component: () => import("../resources/layout/landing/master.component.vue"),
  //   children: LandingRouter,
  // },
  {
    path: "/",
    beforeEnter: guestAuthorized,
    component: () => import("../resources/layout/guest/master.component.vue"),
    children: GuestRouter,
  },
  {
    path: "/auth",
    beforeEnter: guest,
    component: () => import("../resources/layout/auth.component.vue"),
    children: AuthRouter,
  },
  {
    path: "/facility",
    beforeEnter: facilityAuthorized,
    component: () => import("../resources/layout/facility/master.component.vue"),
    children: FacilityRouter,
  },
  {
    path: "/guest",
    beforeEnter: guestAuthorized,
    component: () => import("../resources/layout/guest/master.component.vue"),
    children: GuestRouter,
  },

  {
    path: "/qr",
    beforeEnter: facilityAuthorized,
    component: () => import("../resources/layout/qr/master.component.vue"),
    children: QRRouter,
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

// Assigning title
router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta.title;
  next();
});

export default router;
