const config = {prefix: "/facility/", name: "facility-users"};
export default [

    {
        path: `${config.prefix}users`,
        name: `${config.name}.index`,
        component: () => import('./list.component.vue'),
        meta: {
            title: "Users"
        }
    }
];
