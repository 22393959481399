import DashboardRouter from '../resources/views/facility/dashboard/app.router';
import InventoryRouter from '../resources/views/facility/inventory/app.router';
import BookingInventory from '../resources/views/facility/booking/app.router';
import SettingRouter from '../resources/views/facility/setting/app.router';
import SearchRouter from '../resources/views/facility/search/app.router';
import AccountRouter from '../resources/views/facility/account/app.router';
import FeeRouter from '../resources/views/facility/fee/app.router';
import SalesRouter from '../resources/views/facility/sales/app.router';
import FlightCancellationRouter from '../resources/views/facility/flight-cancellation/app.router';
import CouponRouter from '../resources/views/facility/coupon/app.router';
import UserRouter from '../resources/views/facility/user/app.router';
import ReservationCalcSettingRouter from '../resources/views/facility/reservation-calc-setting/app.router';
import {hasControlAccount, hasEditBooking, hasEditFee} from "@/globals/roles";
const config = {prefix: "/facility"};
export default [
    
    {
        path: `${config.prefix}/dashboard`,
        component: () => import('../resources/views/facility/dashboard/app.component'),
        children: DashboardRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/inventory`,
        component: () => import('../resources/views/facility/inventory/app.component'),
        children: InventoryRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/booking`,
        component: () => import('../resources/views/facility/booking/app.component'),
        children: BookingInventory,
        meta: { }
    },
    {
        path: `${config.prefix}/setting`,
        beforeEnter: hasControlAccount,
        component: () => import('../resources/views/facility/setting/app.component'),
        children: SettingRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/search`,
        component: () => import('../resources/views/facility/search/app.component'),
        children: SearchRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/account`,
        beforeEnter: hasControlAccount,
        component: () => import('../resources/views/facility/account/app.component'),
        children: AccountRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/fees`,
        beforeEnter: hasEditFee,
        component: () => import('../resources/views/facility/fee/app.component'),
        children: FeeRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/sales`,
        component: () => import('../resources/views/facility/sales/app.component'),
        children: SalesRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/flight-cancellations`,
        component: () => import('../resources/views/facility/flight-cancellation/app.component'),
        children: FlightCancellationRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/coupons`,
        beforeEnter: hasEditBooking,
        component: () => import('../resources/views/facility/coupon/app.component'),
        children: CouponRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/users`,
        component: () => import('../resources/views/facility/user/app.component'),
        children: UserRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/reservation-calc-setting`,
        component: () => import('../resources/views/facility/reservation-calc-setting/app.component'),
        children: ReservationCalcSettingRouter,
        meta: { }
    }
];
