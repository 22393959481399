const config = Object.freeze({
    base_url: process.env.BASE_URL,
    api_url: process.env.VUE_APP_API_URL + 'api/',
    google_api: {
        url:  process.env.VUE_APP_GOOGLE_BASE_URL,
        key:  process.env.VUE_APP_GOOGLE_API_KEY,
    },
    line_api: {
        client_id: process.env.VUE_APP_LINE_CLIENT_ID,
        client_secret: process.env.VUE_APP_LINE_CLIENT_SECRET,
        callback_uri: process.env.VUE_APP_LINE_CALLBACK_URL,
        be_callback: 'line/callback'
    },
    airport_id: 1,
    end_point: {
        users: 'users',
        samples: 'samples',
        auth: {
            login: "login",
            reset_password: "forgot-password",
            new_password: "reset-password",
            email_auth: 'send-email-registration',
            register: 'guest/register',
        },
        guest: {
            reservations: 'reservations',
            reservation_update: 'reservation-status/update',
            cars: 'cars',
            card: {
                store: 'save-credit-card',
                get: 'get-credit-card'
            },
            flights: "scheduled-flights",
            parkings_slot: "parkings/slot",
            reservable: "reservations/reservable"
        },
        facility: {
            parkings: 'parkings',
            coupons: 'coupons',
            reserve: 'booked',
            system_configs: 'system-configs',
            reservations: {
                list: 'reservations',
                update: 'reservation-status/update'
            },
            airport: {
                details: 'airport',
                config: 'system-configs',
                inventory: 'inventory-symbols',
                flight: 'scheduled-flights',
                upload: 'import/scheduled-flights',
                calendar: 'calendar-events',
                calendar_import: 'import/calendar-events'
            },
            search: 'search',
            account: {
                list: 'users/facility',
                store: 'airport/register',
                update: 'users',
                delete: 'users'
            },
            reservation_fees: "reservation-fees",
            reservation_calc_setting: {
                base: 'reservation-calc-setting',
                upsert: 'reservation-calc-setting/upsert',
            },
            sales: 'sales-analytics',
            cars: 'cars',
            user: {
                base: "users",
                search: "user-search",
            }
        },
        qr: {
            reservation: "reservation",
        }
    }
});

export default config;
