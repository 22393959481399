import HomeRouter from '../resources/views/landing/home/app.router';
import GuideRouter from '../resources/views/landing/user-guide/app.router';
import PriceRouter from '../resources/views/landing/price/app.router';
import FAQRouter from '../resources/views/landing/faq/app.router';
import AboutRouter from '../resources/views/landing/about-us/app.router';
import TermsRouter from '../resources/views/landing/terms/app.router';
import SCTLRouter from '../resources/views/landing/sctl/app.router';
import P5Router from '../resources/views/landing/reservations/p5/app.router';
import P2SRouter from '../resources/views/landing/reservations/p2s/app.router';
import P2NRouter from '../resources/views/landing/reservations/p2n/app.router';
const config = {prefix: "/"};
export default [

    {
        path: config.prefix,
        component: () => import('../resources/views/landing/home/app.component'),
        children: HomeRouter,
        meta: { }
    },
    {
        path: `${config.prefix}guide`,
        component: () => import('../resources/views/landing/user-guide/app.component'),
        children: GuideRouter,
        meta: { }
    },
    {
        path: `${config.prefix}price`,
        component: () => import('../resources/views/landing/price/app.component'),
        children: PriceRouter,
        meta: { }
    },
    {
        path: `${config.prefix}faq`,
        component: () => import('../resources/views/landing/faq/app.component'),
        children: FAQRouter,
        meta: { }
    },
    {
        path: `${config.prefix}about-us`,
        component: () => import('../resources/views/landing/about-us/app.component'),
        children: AboutRouter,
        meta: { }
    },
    {
        path: `${config.prefix}terms`,
        component: () => import('../resources/views/landing/terms/app.component'),
        children: TermsRouter,
        meta: { }
    },
    {
        path: `${config.prefix}sctl`,
        component: () => import('../resources/views/landing/sctl/app.component'),
        children: SCTLRouter,
        meta: { }
    },
    {
        path: `${config.prefix}reservation/calendar-p5`,
        component: () => import('../resources/views/landing/reservations/p5/app.component'),
        children: P5Router,
        meta: { }
    },
    {
        path: `${config.prefix}reservation/calendar-p2s`,
        component: () => import('../resources/views/landing/reservations/p2s/app.component'),
        children: P2SRouter,
        meta: { }
    },
    {
        path: `${config.prefix}reservation/calendar-p2n`,
        component: () => import('../resources/views/landing/reservations/p2n/app.component'),
        children: P2NRouter,
        meta: { }
    },
];
