const config = {prefix: "/guest/", name: "guest-reservations"};
export default [

    {
        path: `${config.prefix}reservations`,
        name: `${config.name}.index`,
        component: () => import('./list.component.vue'),
        meta: {
            title: "Reservations"
        }
    },
    {
        path: `${config.prefix}reservation/create`,
        name: `${config.name}.create`,
        component: () => import('./form.component.vue'),
        meta: {
            title: "Dashboard"
        }
    },
    {
        path: `${config.prefix}reservation/edit/:id`,
        name: `${config.name}.edit`,
        component: () => import('./form.component.vue'),
        meta: {
            title: "Dashboard"
        }
    },
    {
        path: `${config.prefix}reservation/success`,
        name: `${config.name}.success`,
        component: () => import('./success.component.vue'),
        meta: {
            title: "Success"
        }
    }
];
