import LoginFacilityRouter from '../resources/views/auth/login/facility/app.router';
import LoginGuestRouter from '../resources/views/auth/login/guest/app.router';
import ResetPasswordRouter from '../resources/views/auth/reset-password/app.router';
import NewPasswordRouter from '../resources/views/auth/new-password/app.router';
import EmailAuthRouter from '../resources/views/auth/email-auth/app.router';
import RegisterRouter from '../resources/views/auth/register/app.router';
const config = {prefix: "/auth"};
export default [
    
    {
        path: `${config.prefix}/facility-login`,
        component: () => import('../resources/views/auth/login/facility/app.component'),
        children: LoginFacilityRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/guest-login`,
        component: () => import('../resources/views/auth/login/guest/app.component'),
        children: LoginGuestRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/reset-password`,
        component: () => import('../resources/views/auth/reset-password/app.component'),
        children: ResetPasswordRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/new-password`,
        component: () => import('../resources/views/auth/new-password/app.component'),
        children: NewPasswordRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/email-authentication`,
        component: () => import('../resources/views/auth/email-auth/app.component'),
        children: EmailAuthRouter,
        meta: { }
    },
    {
        path: `${config.prefix}/register/:email/:token`,
        component: () => import('../resources/views/auth/register/app.component'),
        children: RegisterRouter,
        meta: { }
    },
];
