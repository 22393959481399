const config = {prefix: "/facility/", name: "facility-search"};
export default [

    {
        path: `${config.prefix}search`,
        name: `${config.name}.index`,
        component: () => import('./list.component.vue'),
        meta: {
            title: "Search"
        }
    }
];
