import Vue from 'vue'
import Vuex from 'vuex'
import i18n from './i18n'
import {rolesService} from "@/services/roles/roles.service";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    auth: null,
    roles: []
  },
  getters: {
    roles: state => {
      return state.roles
    },
  },
  mutations: {
    auth(state, payload) {
      // mutate state
      state.auth = payload
    },
    roles(state, payload) {
      state.roles = payload
    }
  },
  actions: {
    initialize({ dispatch }) {
      console.info("Site initializing...");
      console.info("Site initialized.");
      const fallback = "en";
      const langEn = /en-.*/;
      const langJP = /ja-.*/;
      const languages = ["ja", "en"];

      let language = langEn.test(navigator.language)
        ? "en"
        : navigator.language;

      language = langJP.test(navigator.language) ? "ja" : language;

      if (!languages.includes(language)) {
        language = fallback;
      }

      i18n.locale = language || "en";
    },

    async roles({ commit }) {
      console.info("Roles initializing...");
      console.info("Roles initialized.");

      if(Vue.$storage.has('auth')) {
        let auth = Vue.$storage.get("auth");
        const response = await rolesService.show(auth.id);
        if(response.data.roles && response.data.roles.length > 0) {
          // Resetting roles
          auth.roles = response.data.roles
          Vue.$storage.set("auth", auth)
          commit('roles', response.data.roles)
        }
      }
    }
  },
});
