import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        Main_0003: '#228EAB',
        Main_0002: '#29AB6C',
        System_00: '#009ECE',
        System_01: '#FF3434',
        Greyscale_002: 'F2F2F2',
        Greyscale_005: '999999',
        Greyscale_007: '676767'
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});

export default vuetify;