const config = {prefix: "/auth/facility-login", name: "facility-login"};

export default [
    {
        path: config.prefix,
        name: `${config.name}`,
        component: () => import('./form.component.vue'),
        meta: {
            title: "Facility Login"
        }
    }
];
