import {
    hasUpdateReservationCalcSetting
} from '@/globals/roles';
const config = {prefix: "/facility/reservation-calc-setting", name: "facility-reservation-calc-setting"};
export default [

    {
        path: `${config.prefix}`,
        name: `${config.name}.index`,
        component: () => import('./list.component.vue'),
        meta: {
            title: "ReservationCalcSetting"
        }
    },
    {
        path: config.prefix + '/:id/edit',
        name: `${config.name}.edit`,
        beforeEnter: hasUpdateReservationCalcSetting,
        component: () => import('./form.component.vue'),
        meta: {
            title: "Edit CalcSetting"
        }
    }
];
