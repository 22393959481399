const config = {prefix: "/auth/new-password/:email/:token", name: "new-password"};

export default [
    {
        path: config.prefix,
        name: `${config.name}`,
        component: () => import('./form.component.vue'),
        meta: {
            title: "New Password"
        }
    }
];
