const config = {prefix: "/facility/fees", name: "facility-fee"};
export default [

    // {
    //     path: config.prefix,
    //     name: `${config.name}.index`,
    //     component: () => import('./list.component.vue'),
    //     meta: {
    //         title: "List Fee"
    //     }
    // },
    {
        path: config.prefix,
        name: `${config.name}.index`,
        component: () => import('./form.component.vue'),
        meta: {
            title: "Edit Reservation Fee"
        }
    }
    // {
    //     path: config.prefix + '/:id/edit',
    //     name: `${config.name}.edit`,
    //     component: () => import('./form.component.vue'),
    //     meta: {
    //         title: "Update Fee"
    //     }
    // }
];
