import Vue from 'vue'

export default class Guard {

    guestAuthorized(to, from, next) {
        // ログイン情報を保有しているクライアント
        if (Vue.$storage.has('auth')) {
            let auth = Vue.$storage.get('auth');
            // ログイン情報が「管理者」だった場合
            if (auth.auth_type == 'airport') {
                next('/facility/dashboard')
            // ログイン情報が「ゲスト」だった場合 & /guestに推移した場合
            } else if (auth.auth_type == 'guest' && to.path === '/guest') {
                next('/guest/dashboard');
            // ログイン情報が「ゲスト」だった場合 & /に推移した場合
            } else if (auth.auth_type == 'guest' && to.path === '/') {
                next('/guest/dashboard');
            // それ以外
            } else {
                next()
            }
        // ログイン情報を保有していないクライアント
        } else {
            // /guestに推移した場合
            if (to.path === '/guest') {
                next('/guest/top');
            // /guest/topに推移した場合
            } else if (to.path === '/guest/top') {
                next();
            // それ以外
            } else {
                next('/auth/guest-login');
            }
        }
    }

    facilityAuthorized(to, from, next) {
        if (Vue.$storage.has('auth')) {
            let auth = Vue.$storage.get('auth');
            if (auth.auth_type == 'guest') next('/guest/dashboard')
            else next()
        } else {
            next('/auth/facility-login')
        }
    }

    guest(to, from, next) {
        if (Vue.$storage.has('auth')) {
            let auth = Vue.$storage.get('auth');
            if (auth.auth_type == 'guest') next('/guest/dashboard')
            if (auth.auth_type == 'airport') next('/facility/dashboard')
        } else {
            if (!Vue.$storage.has('auth')) next();
            else next('/auth/facility-login')
        }
    }
}
const guard = new Guard();

export const guestAuthorized = guard.guestAuthorized,
    facilityAuthorized = guard.facilityAuthorized,
    guest = guard.guest;

