import Vue from "vue";
import { roles } from "../utils/mockup";
import store from "@/store";

export default class Guard {
  hasControlAccount(to, from, next) {
    const auth = Vue.$storage.get('auth')
    if (
      auth.roles.some(
        (role) => role == roles.super_admin || role == roles.control_account
      )
    )
      next();
    else next("/facility/dashboard");
  }

  hasCreateEditInventory(to, from, next) {
    const auth = Vue.$storage.get('auth')
    if (
      auth.roles.some(
        (role) =>
          role == roles.super_admin || role == roles.create_edit_inventory
      )
    )
      next();
    else next("/facility/dashboard");
  }

  hasCreateEditOrChangeInventory(to, from, next) {
    const auth = Vue.$storage.get('auth')
    if (
      auth.roles.some(
        (role) =>
          role == roles.super_admin ||
          role == roles.create_edit_inventory ||
          role == roles.change_inventory
      )
    )
      next();
    else next("/facility/dashboard");
  }

  hasUpdateReservationCalcSetting(to, from, next) {
    const auth = Vue.$storage.get('auth')
    if (
      auth.roles.some(
        (role) =>
          role == roles.super_admin ||
          role == roles.update_calc_setting
      )
    )
      next();
    else next("/facility/dashboard");
  }

  hasEditFee(to, from, next) {
    const auth = Vue.$storage.get('auth')
    if (
      auth.roles.some(
        (role) => role == roles.super_admin || role == roles.edit_fee
      )
    )
      next();
    else next("/facility/dashboard");
  }

  hasEditBooking(to, from, next) {
    const auth = Vue.$storage.get('auth')
    if (
      auth.roles.some(
        (role) => role == roles.super_admin || role == roles.edit_booking
      )
    )
      next();
    else next("/facility/dashboard");
  }
}
const guard = new Guard();

export const hasControlAccount = guard.hasControlAccount,
  hasCreateEditInventory = guard.hasCreateEditInventory,
  hasCreateEditOrChangeInventory = guard.hasCreateEditOrChangeInventory,
  hasUpdateReservationCalcSetting = guard.hasUpdateReservationCalcSetting,
  hasEditFee = guard.hasEditFee,
  hasEditBooking = guard.hasEditBooking;
