const config = {prefix: "/facility/", name: "facility-account"};
export default [

    {
        path: `${config.prefix}account`,
        name: `${config.name}.index`,
        component: () => import('./list.component.vue'),
        meta: {
            title: "Account"
        }
    }
];
